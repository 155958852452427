<template>
  <div
    class="serial-number overflow-auto w-100 h-100 d-flex flex-column justify-content-center align-items-center"
  >
    <base-header
      :title="$t('序號')"
      :is-title-bold="true"
      :left-arrow="true"
      :back-on-click-left="false"
      @on-click-left="showMessageBox"
    ></base-header>

    <div
      class="content-wrapper w-100 h-100 d-flex flex-column justify-content-center align-items-center"
    >
      <div class="title font-weight-bold text-20 mb-3">
        {{ $t('請輸入票券序號') }}
      </div>
      <div
        class="input-wrapper w-100 d-flex justify-content-center align-items-center"
      >
        <b-form class="position-relative" @submit.prevent="onSubmit">
          <div class="w-100 d-flex flex-row align-items-center">
            <b-form-group
              label-for="firstSerialNum"
              class="verifyCode-input mr-1"
              :class="{'has-error': formData.isFirstSerialNumValid === false}"
            >
              <b-form-input
                id="firstSerialNum"
                ref="firstSerialNum"
                v-model="$v.formData.firstSerialNum.$model"
                name="firstSerialNum"
                aria-describedby="input-firstSerialNum-feedback"
                type="text"
                inputmode="numeric"
                pattern="[0-9]*"
                autocomplete="one-time-code"
                placeholder="0000"
                @input="firstSerialNumInputHandler"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label-for="secondSerialNum"
              class="verifyCode-input mr-1"
              :class="{'has-error': formData.isFirstSerialNumValid === false}"
            >
              <b-form-input
                id="secondSerialNum"
                ref="secondSerialNum"
                v-model="$v.formData.secondSerialNum.$model"
                name="secondSerialNum"
                aria-describedby="input-secondSerialNum-feedback"
                type="text"
                inputmode="numeric"
                pattern="[0-9]*"
                autocomplete="one-time-code"
                placeholder="0000"
                :state="formData.isSecondSerialNumValid"
                @input="secondSerialNumInputHandler"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label-for="thirdSerialNum"
              class="verifyCode-input"
              :class="{'has-error': formData.isFirstSerialNumValid === false}"
            >
              <b-form-input
                id="thirdSerialNum"
                ref="thirdSerialNum"
                v-model="$v.formData.thirdSerialNum.$model"
                name="thirdSerialNum"
                aria-describedby="input-thirdSerialNum-feedback"
                type="text"
                inputmode="numeric"
                pattern="[0-9]*"
                autocomplete="one-time-code"
                maxlength="4"
                placeholder="0000"
                :state="formData.isThirdSerialNumValid"
                @input="thirdSerialNumInputHandler"
              ></b-form-input>
            </b-form-group>
          </div>
          <b-form-invalid-feedback
            id="input-firstSerialNum-feedback"
            :state="formData.isFirstSerialNumValid"
            class="mt-0"
          >
            <div
              v-if="
                !$v.formData.firstSerialNum.required &&
                  !$v.formData.secondSerialNum.required &&
                  !$v.formData.thirdSerialNum.required
              "
              class="error position-absolute"
            >
              {{ $t('*請輸入序號') }}
            </div>
            <div
              v-else-if="backendError === 'code is used'"
              class="error position-absolute"
            >
              {{ $t('*序號已被使用') }}
            </div>
            <div
              v-else-if="backendError === 'code is expired'"
              class="error position-absolute"
            >
              {{ $t('*序號已失效') }}
            </div>
            <div
              v-else-if="backendError === 'code is refund'"
              class="error position-absolute"
            >
              {{ $t('*此序號已退費') }}
            </div>

            <div v-else class="error position-absolute">
              {{ $t('*此序號不存在') }}
            </div>
          </b-form-invalid-feedback>
        </b-form>
      </div>
      <button
        type="submit"
        class="w-100 btn btn-primary mb-2"
        @click="onSubmit()"
      >
        {{ $t('確定') }}
      </button>
      <div class="no-serial">
        <span>{{ $t('沒有序號嗎？前往') }}</span>
        <a href="https://www.gogoldtalk.com/course/174219">{{
          $t('獲得序號')
        }}</a>
      </div>
    </div>
    <modal-message-box
      v-if="isModalMessageBoxShow"
      :result-content="[$t('系統將為你登出帳號，'), $t('確定要返回上一頁嗎？')]"
      :buttons="messageBoxButtons"
      @on-item-click="onModalMessageBoxClick"
    >
    </modal-message-box>
  </div>
</template>

<script>
import BaseHeader from '@/components/Base/BaseHeader';
import {required, minLength, maxLength} from 'vuelidate/lib/validators';
import ModalMessageBox from '@/components/Modal/ModalMessageBox.vue';
import userService from '@/services/user';
import BackEvent from '@/lib/base/backEvent.js';

export default {
  components: {
    BaseHeader,
    ModalMessageBox,
  },
  data() {
    return {
      formData: {
        firstSerialNum: '',
        secondSerialNum: '',
        thirdSerialNum: '',
        isFirstSerialNumValid: null,
        isSecondSerialNumValid: null,
        isThirdSerialNumValid: null,
      },
      backendError: '',
      completeSerialNum: '',
      isModalMessageBoxShow: false,
      messageBoxButtons: [
        {
          variant: 'secondary',
          value: 'cancel',
          content: this.$t('取消'),
        },
        {
          variant: 'primary',
          value: 'confirm',
          content: this.$t('確定'),
        },
      ],
    };
  },
  validations: {
    formData: {
      firstSerialNum: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(4),
      },
      secondSerialNum: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(4),
      },
      thirdSerialNum: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(4),
      },
    },
  },
  computed: {
    userData() {
      return this.$store.getters['user/userData'];
    },
  },
  watch: {
    'formData.firstSerialNum': {
      immediate: true,
      deep: true,
      handler(newValue) {
        // 由於 String.prototype.replaceAll() 並只適用於版本 v14.15.0 以上的 Node.js
        // 因此以 String.prototype.replace() 搭配正規表達式代替
        const regex = /-/g; // 'g'代表所有出現且匹配的表達式
        const spiltValue = newValue.replace(regex, '');
        if (this.formData.firstSerialNum.length === 4) {
          this.$refs.secondSerialNum.focus();
        }
        if (newValue.length > 4) {
          this.formData.firstSerialNum = spiltValue.substring(0, 4);
          this.formData.secondSerialNum = spiltValue.substring(4, 8);
          this.formData.thirdSerialNum = spiltValue.substring(8, 12);
          this.$refs.secondSerialNum.focus();
        }
        this.completeSerialNum = this.formData.firstSerialNum.concat(
          this.formData.secondSerialNum,
          this.formData.thirdSerialNum
        );
      },
    },
    'formData.secondSerialNum': {
      immediate: true,
      deep: true,
      handler(newValue) {
        const regex = /-/g;
        const spiltValue = newValue.replace(regex, '');
        if (this.formData.secondSerialNum.length >= 4) {
          this.$refs.thirdSerialNum.focus();
        }
        if (newValue.length > 4) {
          this.formData.secondSerialNum = spiltValue.substring(0, 4);
          this.formData.thirdSerialNum = spiltValue.substring(4, 8);
        }
        this.completeSerialNum = this.formData.firstSerialNum.concat(
          this.formData.secondSerialNum,
          this.formData.thirdSerialNum
        );
      },
    },
    'formData.thirdSerialNum': {
      immediate: true,
      deep: true,
      handler() {
        this.completeSerialNum = this.formData.firstSerialNum.concat(
          this.formData.secondSerialNum,
          this.formData.thirdSerialNum
        );
      },
    },
  },
  created() {
    BackEvent.addEvent(() => {
      this.showMessageBox();
    });
  },
  methods: {
    showMessageBox() {
      BackEvent.popEvent();
      BackEvent.addEvent(() => {
        this.onModalMessageBoxClick('cancel');
      });
      this.isModalMessageBoxShow = true;
    },
    async onSubmit() {
      this.$v.$touch();
      this.formData.isFirstSerialNumValid = null;
      this.formData.isSecondSerialNumValid = null;
      this.formData.isThirdSerialNumValid = null;
      if (this.$v.$invalid) {
        this.formData.isFirstSerialNumValid = false;
        this.focusInput();
      } else {
        await this.activeByCode();
      }
    },

    firstSerialNumInputHandler() {
      this.formData.isFirstSerialNumValid = null;
    },
    secondSerialNumInputHandler() {
      this.formData.isSecondSerialNumValid = null;
    },
    thirdSerialNumInputHandler() {
      this.formData.isThirdSerialNumValid = null;
    },

    validateFirstSerialNum() {
      const {$error} = this.$v.formData.firstSerialNum;
      return !$error;
    },
    validateSecondSerialNum() {
      const {$error} = this.$v.formData.secondSerialNum;
      return !$error;
    },
    validateThirdSerialNum() {
      const {$error} = this.$v.formData.thirdSerialNum;
      return !$error;
    },
    focusInput() {
      if (!this.validateFirstSerialNum()) {
        this.$refs.firstSerialNum.focus();
      } else if (!this.validateSecondSerialNum()) {
        this.$refs.secondSerialNum.focus();
      } else if (!this.validateThirdSerialNum()) {
        this.$refs.thirdSerialNum.focus();
      } else {
        return false;
      }
    },
    activeByCode() {
      this.$store.commit('env/setIsLoading', true);
      return this.$store
        .dispatch('user/activateByCode', {
          academyId: this.userData.academyInfo.academyId,
          code: this.completeSerialNum,
        })
        .then(async () => {
          this.$Message.success(this.$t('成功啟用序號'));
          await this.updateUser();
        })
        .catch((error) => {
          this.backendError = error.response.data.errors[0];
          if (this.backendError !== '') {
            this.formData.isFirstSerialNumValid = false;
          }
        })
        .finally(() => {
          this.$store.commit('env/setIsLoading', false);
        });
    },
    onModalMessageBoxClick(value) {
      BackEvent.popEvent();
      if (value === 'confirm') {
        return this.logout();
      } else {
        BackEvent.addEvent(() => {
          this.showMessageBox();
        });
        this.isModalMessageBoxShow = false;
      }
    },
    async logout() {
      return this.$store.dispatch('user/logout').then(() => {
        return this.$router.push({name: 'login'});
      });
    },
    async updateUser() {
      return userService
        .updateUser({currentPlan: 'vip'})
        .then(() => {
          return this.$store.dispatch('user/loginWithJwt');
        })
        .then(() => {
          return this.$router.push('/');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.serial-number {
  position: absolute;
  z-index: 2;
  .content-wrapper {
    padding: calc((100% - 238px) / 2);
    .title {
      height: 34px;
    }
    .form-group {
      width: 74px;
      &.has-error {
        margin-bottom: 32px;
      }
    }
    .no-serial {
      height: 28px;
      a {
        color: $accent;
        text-decoration: underline;
      }
    }
  }
  .error {
    height: 16px;
    color: $negative;
    font-size: 12px;
    text-align: start;
    margin-bottom: 16px;
    top: 42px;
  }
}
</style>
